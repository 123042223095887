import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { SortOptions } from '../../config/materialTabConfig';
import mockMaterialList from '../../services/mock/materialList.json';
import mockPlatformList from '../../services/mock/platformList.json';
import mockPositionList from '../../services/mock/positionList.json';
import { MaterialTable } from '../../components/material/materialTable';
import { MaterialType } from '../../types/material.types';
import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../theme';
import { Panel } from '../../components/shared/panel/panel';
import { MultiSelectDropDown } from '../../components/shared/dropdown/multiSelect';
import { Search } from '../../components/shared/search/search';
import { CustomButton } from '../../components/shared/button/button';
import { Typography } from '@mui/material';
import { SingleSelectDropDown } from '../../components/shared/dropdown/singleSelect';
import { MaterialPlatformEdit } from './materialPlatformEdit';
import { Header } from '../../components/shared/header/header';

export const testid = 'material-platform';

const color = colors();
const themes = theme().typography;
export const MaterialPlatform = (): ReactElement => {
	const classes = useStyles();

	const [activeRow, setActiveRow] = useState<MaterialType>();
	const [platform, setPlatform] = useState<string[]>([]);
	const [position, setPosition] = useState<string[]>([]);
	const [searchText, setSearchText] = useState<string>('');
	const [sortOption, setSortOption] = useState<string>('0');
	const [openPanel, setOpenPanel] = useState<boolean>(false);
	const [isSave, setIsSave] = useState<boolean>(false);

	useEffect(() => {
		if (isSave) setOpenPanel(false);
	}, [isSave]);

	const onClearClick = useCallback(() => {
		setPlatform([]);
		setPosition([]);
		setSearchText('');
	}, []);

	return (
		<div>
			<Header title="Materials" data-testid={`${testid}-title`} />
			<div className={classes.topPadding}>
				<MultiSelectDropDown
					label="Platform"
					data={mockPlatformList.map((list) => list.value)}
					value={platform}
					onSelectCallback={setPlatform}
				/>
				<MultiSelectDropDown
					label="Position"
					data={mockPositionList.map((list) => list.value)}
					value={position}
					onSelectCallback={setPosition}
				/>
				<Search
					placeholder="Search Material Name, Material ID..."
					value={searchText}
					onSearchCallback={setSearchText}
				/>
				<CustomButton id="search" text="Search" width={90} variant="outlined" />
				{(platform.length > 0 || position.length > 0 || searchText) && (
					<CustomButton id="clear" text="Clear" width={90} onClickCallback={onClearClick} />
				)}
			</div>
			<div className={classes.actions}>
				<Typography
					{...themes.bodyRegular}
					alignItems="center"
					display="flex"
					color={color.neutral[8]}
					marginLeft="8px"
				>
					Sort By:
				</Typography>
				<SingleSelectDropDown
					label=""
					data={SortOptions}
					value={sortOption}
					onSelectCallback={setSortOption}
					isTextSelect
				/>
			</div>
			<MaterialTable
				data={mockMaterialList}
				onCellClickCallback={setActiveRow}
				onEditClickCallback={() => setOpenPanel(true)}
			></MaterialTable>
			{activeRow && (
				<Panel
					anchor="right"
					open={openPanel}
					title={`${activeRow.materialId} ${activeRow.materialName}`}
					onCloseCallback={setOpenPanel}
					component={<MaterialPlatformEdit data={activeRow} isSave={setIsSave} />}
				/>
			)}
		</div>
	);
};

const useStyles = makeStyles(() => ({
	topPadding: {
		paddingTop: '15px',
		'& #clear': {
			backgroundColor: color.primary[100],
			color: color.text.light,
		},
	},
	actions: {
		display: 'flex',
		'& .MuiFormControl-root': {
			margin: '8px 0px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
	},
}));
