import React, { ReactElement, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
	FormControlLabel,
	IconButton,
	Pagination,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ChatIcon from '@mui/icons-material/Chat';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { colors, theme } from '../../theme';
import { CustomButton } from '../../components/shared/button/button';
import { CustomDate } from '../../components/shared/date/date';
import { CustomDialog } from '../../components/shared/dialog/dialog';
import { EditPanel } from '../../components/materialRoutine/editPanel';
import { MultiSelectDropDown } from '../../components/shared/dropdown/multiSelect';
import { Search } from '../../components/shared/search/search';
import { ToolTip } from '../../components/shared/tooltip/tooltip';
import { GetStatus, SkipValues } from '../../utils/routine';
import {
	DeactivateRoutineRequest,
	ListType,
	MatRoutineType,
	MaterialRoutineMaster,
	MaterialRoutineType,
	PostResponse,
	RoutineType,
	SaveMaterialRoutineRequest,
} from '../../types/routine.types';
import { Status } from '../../config/routinesConfig';
import { useAsyncOperation } from '../../utils/use-async-operation';
import {
	stopRoutine,
	getMaterialRoutines,
	getMatRoutineMaster,
	saveMaterialRoutine,
} from '../../services/material-routine-service';
import { Loader } from '../../components/shared/loader/loader';
import { CustomAlert } from '../../components/shared/alert/alert';

export const testid = 'material-routine';

const color = colors();
const themes = theme().typography;

export const MaterialRoutine = (): ReactElement => {
	const classes = useStyles();
	const isAdmin: boolean = localStorage.getItem('roles')?.includes('TT.ReAdmin') || false;

	const [verRoutineList, setVerRoutineList] = useState<RoutineType[]>([]);
	const [docList, setDocList] = useState<ListType[]>([]);
	const [matTypeList, setMatTypeList] = useState<ListType[]>([]);
	const [matRoutines, setMatRoutines] = useState<MaterialRoutineType[]>([]);
	const [materialType, setMaterialType] = useState<string[]>([]);
	const [appliedRoutine, setAppliedRoutine] = useState<string[]>([]);
	const [requiredDoc, setRequiredDoc] = useState<string[]>([]);
	const [status, setStatus] = useState<string[]>([]);
	const [searchText, setSearchText] = useState<string>();
	const [matRoutine, setMatRoutine] = useState<MatRoutineType>();
	const [deactiveDate, setDeactiveDate] = useState<string>();
	const [deactivateOpen, setDeactivateOpen] = useState<boolean>(false);
	const [disableActions, setDisableActions] = useState<boolean>(false);
	const [openError, setOpenError] = useState(false);
	const [openSaveAlert, setOpenSaveAlert] = useState(false);
	const [showBlanks, setShowBlanks] = useState(false);

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState<number>(25);
	const [pageCount, setPageCount] = useState<number>(1);

	const [getMatRoutines, matRoutineBusy, materialRoutines, matRoutineError] =
		useAsyncOperation<MaterialRoutineType[]>(getMaterialRoutines);
	const [getMasterData, masterBusy, masterData, masterError] =
		useAsyncOperation<MaterialRoutineMaster>(getMatRoutineMaster);
	const [saveMatRoutine, saveBusy, saveData, saveError] = useAsyncOperation<PostResponse>(saveMaterialRoutine);
	const [deactivateRoutine, deactivateBusy, deactivateData, deactivateError] =
		useAsyncOperation<PostResponse>(stopRoutine);

	useEffect(() => {
		getMasterData();
		getMatRoutines();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (matRoutineError || masterError) setOpenError(true);
	}, [matRoutineError, masterError]);

	useEffect(() => {
		if (masterData) {
			setVerRoutineList(masterData.routines);
			setDocList(masterData.documentMaster);
			setMatTypeList(masterData.materialTypes);
		}
	}, [masterData]);

	useEffect(() => {
		if (materialRoutines) {
			setShowBlanks(false);
			onClearClick();
			setMatRoutines(materialRoutines);
		}
	}, [materialRoutines]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (deactivateData) {
			getMatRoutines();
		}
	}, [deactivateData]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (saveData) {
			getMatRoutines();
		}
	}, [saveData]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (deactivateData || saveData || deactivateError || saveError) setOpenSaveAlert(true);
	}, [deactivateData, saveData, deactivateError, saveError]);

	useEffect(() => {
		setPageCount(Math.floor(matRoutines.length / rowsPerPage));
	}, [rowsPerPage, matRoutines]);

	useEffect(() => {
		onClearClick();
		if (showBlanks && materialRoutines) setMatRoutines(materialRoutines.filter((d) => d.routines.length === 0));
	}, [showBlanks]); // eslint-disable-line react-hooks/exhaustive-deps

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
	};

	const handleRowPerPageChange = (value: string) => {
		setRowsPerPage(parseInt(value));
		setPage(1);
	};

	const onClearClick = () => {
		setMaterialType([]);
		setAppliedRoutine([]);
		setRequiredDoc([]);
		setStatus([]);
		setSearchText(undefined);
		setMatRoutines(materialRoutines || []);
		if (showBlanks && materialRoutines) setMatRoutines(materialRoutines.filter((d) => d.routines.length === 0));
	};

	const onSearchClick = () => {
		setPage(1);
		let data = materialRoutines || [];
		if (materialType.length > 0) {
			data = data.filter((d) => materialType.includes(d.materialType));
			setMatRoutines(showBlanks ? data.filter((d) => d.routines.length === 0) : data);
		}
		if (appliedRoutine.length > 0 && data.length > 0) {
			data = data
				.map((d) => {
					return { ...d, routines: d.routines.filter((r) => appliedRoutine.includes(r.routineName)) };
				})
				.filter((d) => d.routines.length > 0);
			setMatRoutines(data);
		}
		if (requiredDoc.length > 0 && data.length > 0) {
			data = data
				.map((d) => {
					return { ...d, routines: d.routines.filter((r) => requiredDoc.includes(r.documentName)) };
				})
				.filter((d) => d.routines.length > 0);
			setMatRoutines(data);
		}
		if (status.length > 0 && data.length > 0) {
			data = data
				.map((d) => {
					return {
						...d,
						routines: d.routines.filter((r) => status.includes(GetStatus(r.activeDate, r.endDate).statusName)),
					};
				})
				.filter((d) => d.routines.length > 0);
			setMatRoutines(data);
		}
		if (searchText && data.length > 0) {
			data = data.filter(
				(d) =>
					d.materialCompositionTypeName.toLowerCase().includes(searchText.toLowerCase()) ||
					d.materialCompositionTypeId.includes(searchText)
			);
			setMatRoutines(showBlanks ? data.filter((d) => d.routines.length === 0) : data);
		}
	};

	const handleDeactiveClick = (row: MatRoutineType) => {
		setMatRoutine(row);
		setDeactivateOpen(true);
	};

	const handleAddClick = (matId: string) => {
		let data = [...matRoutines];
		data
			.filter((d) => d.materialCompositionTypeId === matId)[0]
			.routines.push({ ruleId: '-1', isModify: true } as MatRoutineType);
		setMatRoutines(data);
		setDisableActions(true);
	};

	const handleEditClick = (matId: string, row: MatRoutineType) => {
		let data = [...matRoutines];
		data
			.filter((d) => d.materialCompositionTypeId === matId)[0]
			.routines.filter((r) => r.ruleId === row.ruleId)[0].isModify = true;
		data
			.filter((d) => d.materialCompositionTypeId === matId)[0]
			.routines.filter((r) => r.ruleId === row.ruleId)[0].isEdit = true;
		setMatRoutines(data);
		setDisableActions(true);
	};

	const onDeactivateSaveClick = () => {
		let deactivateData: DeactivateRoutineRequest = {
			ruleId: matRoutine?.ruleId || '',
			endDate: deactiveDate || '',
		};

		deactivateRoutine(deactivateData);
		setDeactivateOpen(false);
		setMatRoutine(undefined);
		setDeactiveDate(undefined);
	};

	const clearFlag = (matId: string) => {
		let data = [...matRoutines];
		if (data.filter((d) => d.materialCompositionTypeId === matId)[0].routines.filter((r) => r.isEdit).length > 0) {
			data
				.filter((d) => d.materialCompositionTypeId === matId)[0]
				.routines.map((r) => {
					r.isModify = false;
					r.isEdit = false;
				});
		} else {
			let index = data.filter((d) => d.materialCompositionTypeId === matId)[0].routines.length;
			data.filter((d) => d.materialCompositionTypeId === matId)[0].routines.splice(index - 1, 1);
		}

		setMatRoutines(data);
		setDisableActions(false);
	};

	const onSaveClickCallback = (matId: string, data: MatRoutineType) => {
		clearFlag(matId);
		let routine: SaveMaterialRoutineRequest = {
			materialCompositionTypeId: matId,
			materialCompositionTypeName: matRoutines.filter((m) => m.materialCompositionTypeId === matId)[0]
				.materialCompositionTypeName,
			materialType: matRoutines.filter((m) => m.materialCompositionTypeId === matId)[0].materialType,
			routine: data,
		};

		saveMatRoutine(routine);
	};

	return (
		<>
			<div className={classes.topPadding}>
				<Loader
					key={`${testid}-loader`}
					open={matRoutineBusy || masterBusy || saveBusy || deactivateBusy}
					message={matRoutineBusy || masterBusy ? 'Loading...' : 'Updating...'}
				/>
				<CustomAlert
					open={openError}
					message="Something went wrong while retrieving data!"
					severity="error"
					timeout={5000}
					isFade={false}
					onCloseCallback={() => {
						setOpenError(false);
					}}
				/>
				<MultiSelectDropDown
					label="Material type"
					width={185}
					data={matTypeList.map((list) => list.name)}
					value={materialType}
					onSelectCallback={setMaterialType}
				/>
				{!showBlanks && (
					<MultiSelectDropDown
						label="Applied routine"
						width={185}
						data={verRoutineList.map((list) => list.routineName)}
						value={appliedRoutine}
						onSelectCallback={setAppliedRoutine}
					/>
				)}
				{!showBlanks && (
					<MultiSelectDropDown
						label="Required document"
						width={185}
						data={docList.map((list) => list.name)}
						value={requiredDoc}
						onSelectCallback={setRequiredDoc}
					/>
				)}
				{!showBlanks && (
					<MultiSelectDropDown
						label="Status"
						width={185}
						data={Status && Status.map((list) => list.name)}
						value={status}
						onSelectCallback={setStatus}
					/>
				)}
				<Search placeholder="Search material name or ID" value={searchText || ''} onSearchCallback={setSearchText} />
				<CustomButton id="search" text="Search" width={90} variant="outlined" onClickCallback={onSearchClick} />
				{(materialType.length > 0 ||
					appliedRoutine.length > 0 ||
					requiredDoc.length > 0 ||
					status.length > 0 ||
					searchText !== undefined) && (
					<CustomButton id="clear" text="Clear" width={90} onClickCallback={onClearClick} />
				)}
				<FormControlLabel
					className={classes.label}
					control={
						<Switch checked={showBlanks} onClick={() => setShowBlanks(!showBlanks)} data-testid={`${testid}-switch`} />
					}
					label="Only Blanks"
					labelPlacement="start"
				/>
			</div>
			<TableContainer className={classes.table} data-testid={`${testid}-container`}>
				<Table stickyHeader>
					<TableHead>
						<TableRow key={`${testid}-header`}>
							<TableCell width="5%">ID</TableCell>
							<TableCell width="20%">Material Name</TableCell>
							<TableCell width="44%">Required Document</TableCell>
							<TableCell width="10%">Skippable</TableCell>
							<TableCell width="20%">Status</TableCell>
							{isAdmin && <TableCell width="1%" />}
						</TableRow>
					</TableHead>
					<TableBody data-testid={`${testid}-body`}>
						{(rowsPerPage > 0
							? matRoutines.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
							: matRoutines
						).map((mat) =>
							mat.routines === null || mat.routines.length === 0 ? (
								<TableRow
									hover
									key={`${testid}-${mat.materialCompositionTypeId}-0`}
									data-testid={`${testid}-${mat.materialCompositionTypeId}-0`}
								>
									<TableCell>{mat.materialCompositionTypeId}</TableCell>
									<TableCell>
										<div>{mat.materialCompositionTypeName}</div>
										<div className={classes.secondaryText}>{mat.materialType}</div>
									</TableCell>
									<TableCell>--</TableCell>
									<TableCell>--</TableCell>
									<TableCell>--</TableCell>
									{isAdmin && (
										<TableCell align="right">
											<ToolTip
												tooltipText="Add routine"
												children={
													<span>
														<IconButton
															id="add-routine"
															className={classes.iconButton}
															disabled={disableActions}
															onClick={() => handleAddClick(mat.materialCompositionTypeId)}
														>
															<AddIcon sx={{ color: disableActions ? color.neutral[5] : color.primary[90] }} />
														</IconButton>
													</span>
												}
											/>
										</TableCell>
									)}
								</TableRow>
							) : (
								<>
									{mat.routines.map((row, i) => (
										<TableRow
											hover={row.isModify ? false : true}
											key={`${testid}-${mat.materialCompositionTypeId}-${row.ruleId}`}
											data-testid={`${testid}-${mat.materialCompositionTypeId}-${row.ruleId}`}
										>
											{row.isModify ? (
												<EditPanel
													materialId={mat.materialCompositionTypeId}
													materialName={mat.materialCompositionTypeName}
													materialType={mat.materialType}
													row={row}
													verRoutinesList={verRoutineList}
													onDiscardClick={clearFlag}
													onSaveClick={onSaveClickCallback}
												/>
											) : (
												<>
													<TableCell className={i < mat.routines.length - 1 ? classes.noBorder : ''}>
														{i === 0 && mat.materialCompositionTypeId}
													</TableCell>
													<TableCell className={i < mat.routines.length - 1 ? classes.noBorder : ''}>
														<div>{i === 0 && mat.materialCompositionTypeName}</div>
														<div className={classes.secondaryText}>{i === 0 && mat.materialType}</div>
													</TableCell>
													<TableCell>
														<div className={classes.skip}>
															{row.documentName}
															{row.comments && (
																<ToolTip
																	tooltipText={row.comments}
																	children={
																		<span>
																			<ChatIcon id="comment" />
																		</span>
																	}
																/>
															)}
														</div>
														<div className={classes.secondaryText}>{row.routineName}</div>
													</TableCell>
													<TableCell>
														<div className={classes.skip}>
															{row.routineName !== 'NA' &&
																(row.isShellSkippable ||
																row.isLiningSkippable ||
																row.isPaddingSkippable ||
																row.skippablePct > 0
																	? 'Skippable'
																	: 'Not Skippable')}
															{row.skippablePct > 0 && (
																<ToolTip
																	tooltipText={`This document is skippable if the material composition is less than ${row.skippablePct}%`}
																	children={
																		<span>
																			<InfoOutlinedIcon id="skippct" />
																		</span>
																	}
																/>
															)}
														</div>
														<div className={classes.secondaryText}>
															{row.routineName !== 'NA' &&
																SkipValues(
																	row.isShellSkippable,
																	row.isLiningSkippable,
																	row.isPaddingSkippable,
																	row.skippablePct > 0
																)}
														</div>
													</TableCell>
													<TableCell>
														<div
															className={classes.status}
															style={{
																background: `${GetStatus(row.activeDate, row.endDate).backgroundColor}`,
																color: `${GetStatus(row.activeDate, row.endDate).textColor}`,
															}}
														>
															{GetStatus(row.activeDate, row.endDate).status}
														</div>
														{(row.activeDate || row.endDate) && (
															<div className={classes.secondaryText}>
																{GetStatus(row.activeDate, row.endDate).statusDuration}
															</div>
														)}
													</TableCell>
													{isAdmin && (
														<TableCell align="right">
															<div className={classes.actions}>
																{!row.endDate && (!row.activeDate || new Date() > new Date(row.activeDate)) && (
																	<ToolTip
																		tooltipText="Deactivate"
																		children={
																			<span>
																				<IconButton
																					id="deactivate"
																					className={classes.iconButton}
																					disabled={disableActions}
																					onClick={() => handleDeactiveClick(row)}
																				>
																					<PowerSettingsNewIcon
																						sx={{ color: disableActions ? color.neutral[5] : color.primary[90] }}
																					/>
																				</IconButton>
																			</span>
																		}
																	/>
																)}
																{!row.endDate && row.activeDate && new Date() < new Date(row.activeDate) && (
																	<ToolTip
																		tooltipText="Edit routine"
																		children={
																			<span>
																				<IconButton
																					id="edit-doc"
																					className={classes.iconButton}
																					disabled={disableActions}
																					onClick={() => handleEditClick(mat.materialCompositionTypeId, row)}
																				>
																					<EditOutlinedIcon
																						sx={{ color: disableActions ? color.neutral[5] : color.primary[90] }}
																					/>
																				</IconButton>
																			</span>
																		}
																	/>
																)}
																<ToolTip
																	tooltipText="Add routine"
																	children={
																		<span>
																			<IconButton
																				id="add-routine"
																				className={classes.iconButton}
																				disabled={disableActions}
																				onClick={() => handleAddClick(mat.materialCompositionTypeId)}
																			>
																				<AddIcon
																					fontSize="inherit"
																					sx={{ color: disableActions ? color.neutral[5] : color.primary[90] }}
																				/>
																			</IconButton>
																		</span>
																	}
																/>
															</div>
														</TableCell>
													)}
												</>
											)}
										</TableRow>
									))}
								</>
							)
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				data-testid={`${testid}-pagination`}
				className={classes.pagination}
				rowsPerPageOptions={[25, 50, 100]}
				rowsPerPage={rowsPerPage}
				count={matRoutines.length}
				page={page - 1}
				onPageChange={() => {}}
				onRowsPerPageChange={(e) => handleRowPerPageChange(e.target.value)}
				ActionsComponent={() => (
					<Pagination
						data-testid={`${testid}-node`}
						count={matRoutines.length % rowsPerPage === 0 ? pageCount : pageCount + 1}
						page={page}
						onChange={handlePageChange}
						shape="rounded"
					/>
				)}
			/>
			<CustomDialog
				open={deactivateOpen}
				title="Deactivate routine"
				yesButtonText="Apply"
				noButtonText="Cancel"
				yesButtonDisable={!deactiveDate ? true : false}
				onYesClickCallback={onDeactivateSaveClick}
				onNoClickCallback={() => {
					setDeactivateOpen(false);
					setDeactiveDate(undefined);
				}}
				onCloseCallback={() => {
					setDeactivateOpen(false);
					setDeactiveDate(undefined);
				}}
				component={
					<>
						<CustomDate
							width={332}
							label=""
							labelText="Deactivate date"
							format="DD MMM, YYYY"
							onChangeCallback={setDeactiveDate}
							disablePast
						/>
						<div className={classes.skipDialogText}>
							Orders with an OPD after the specified deactivation date will not be subject to the routine
							<b> {matRoutine?.documentName}</b> (<b>{matRoutine?.routineName}</b>).
						</div>
					</>
				}
			/>
			{openSaveAlert && (
				<div className={classes.saveAlert}>
					<CustomAlert
						open={openSaveAlert}
						timeout={5000}
						message={
							(deactivateData || saveData) && !deactivateError && !saveError
								? 'Rule saved successfully!'
								: 'Something went wrong!'
						}
						severity={(deactivateData || saveData) && !deactivateError && !saveError ? 'success' : 'error'}
						onCloseCallback={() => {
							setOpenSaveAlert(false);
						}}
					/>
				</div>
			)}
		</>
	);
};

const useStyles = makeStyles(() => ({
	topPadding: {
		paddingTop: '15px',
		'& #clear': {
			backgroundColor: color.primary[100],
			color: color.text.light,
		},
	},
	table: {
		marginTop: '15px',
		height: 'calc(100% - 235px)',
		'& .MuiTableCell-head': {
			...themes.bodyMedium,
			color: color.primary[100],
			borderTop: `1px solid ${color.neutral[2]}`,
		},
		'& .MuiTableCell-body': {
			...themes.bodyRegular,
			color: color.text.dark,
		},
		'& .MuiTableCell-root': {
			borderBottom: `1px solid ${color.neutral[2]}`,
			padding: '13px 8px',
		},
		'& .MuiTableRow-hover': {
			'&:hover': {
				backgroundColor: `${color.signal.infoLight} !important`,
			},
		},
	},
	noBorder: {
		borderBottom: 'none !important',
	},
	secondaryText: {
		...themes.metaText,
		color: color.neutral[8],
	},
	skip: {
		display: 'flex',
		alignItems: 'center',
		'& #skippct, #comment': {
			display: 'flex',
			width: '16px',
			height: '16px',
			color: color.primary[90],
			marginLeft: '5px',
			cursor: 'pointer',
		},
	},
	status: {
		...themes.metaText,
		width: 'fit-content',
		padding: '0px 8px',
		borderRadius: '4px',
	},
	skipDialogText: {
		...themes.metaText,
		margin: '20px 8px',
	},
	iconButton: {
		padding: '5px !important',
		'& svg': {
			width: '16px',
			height: '16px',
		},
	},
	actions: {
		display: 'flex',
		justifyContent: 'end',
	},
	pagination: {
		'& .MuiToolbar-root.MuiTablePagination-toolbar': {
			position: 'absolute',
			right: 20,
			paddingTop: '5px',
		},
		'& .MuiButtonBase-root.Mui-selected': {
			backgroundColor: color.primary[100],
			color: color.shades.white,
		},
		'& .MuiButtonBase-root.Mui-selected:hover': {
			backgroundColor: color.primary[100],
		},
		'& .MuiPagination-root.MuiPagination-text': {
			flexShrink: 0,
			marginLeft: '10px',
		},
	},
	saveAlert: {
		position: 'fixed',
		top: '10px',
		left: '50%',
		transform: 'translateX(-50%)',
	},
	label: {
		position: 'absolute',
		right: '30px',
		paddingTop: '8px',
		'& .MuiTypography-root': {
			...themes.bodyRegular,
		},
	},
}));
